<template>
  <a-checkbox
    v-model="defaultCheck"
    :disabled="canSelect? true : false"
    @change="checkboxChange"
  />
</template>
<script>
export default {
  name: "HCheck",
  data() {
    return {
      defaultCheck: false
    };
  },
  watch:{
    isCheck:{
      immediate:true,
      handler(newValue) {
        this.defaultCheck = newValue
      }
    }
  },
  props: ["isCheck", "canSelect"],
  methods: {
    checkboxChange() {
      this.$emit("update:isCheck", this.defaultCheck);
      this.$emit("datachange")
    }
  }
};
</script>
