<!--
 * @Author: sq
 * @Date: 2020-09-18 11:15:28
 * @LastEditTime: 2024-03-26 18:13:09
 * @LastEditors: mayu.ex 15163353598@163.com
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\projectCollapse\projectCollapse.vue
-->

<template>
  <div class="project-box">
    <!-- 工程内容  非折叠区 -->
    <div class="project-card">
      <div class="project-card-img">
        <div class="i-tag">
          <div class="content bg0" v-if="data.status == 0">未开始</div>
          <div class="content bg1" v-if="data.status == 1">进行中</div>
          <div class="content bg2" v-if="data.status == 2">已结束</div>
        </div>
        <div class="i-time">
          {{ data.beginDate }}<span class="spec">至</span>{{ data.endDate }}
        </div>
        <!-- <h-img :imgUrl="data.img" :errorImg="data.defaultImg"></h-img> -->
        <img :src="data.image" alt="" srcset="" />
      </div>
      <div class="project-card-con">
        <div class="project-card-con-title">
          <span class="lab">编码：{{ data.projectCode || "--" }}</span>
<!--          <span class="lab">名称：{{ data.projectName || "&#45;&#45;" }}</span>-->
          <span class="lab">类型：{{ data.primaryCategoryName || "--" }}</span>
          <span class="orgname-wrap">{{ data.orgId_name }}</span>
        </div>
        <div class="project-card-con-desc topOne">
          <div class="project-card-con-desc-menu">
            使用单位：{{ data.customerName }}
          </div>
          <div class="project-card-con-desc-menu">
            使用地址：{{ data.customerAddr }}
          </div>
<!--          <div class="project-card-con-desc-menu">-->
<!--            经办业务员：{{ data.agent }}-->
<!--          </div>-->
        </div>
        <div class="project-card-con-desc topTwo">
          <div class="project-card-con-desc-menu">
            联系人：{{ data.customerContact }}-{{ data.customerTel }}
          </div>

          <div class="project-card-con-desc-menu" v-if="data.bidOrg">
            招标单位：{{ data.bidOrg }}
          </div>

        </div>
        <div class="project-card-con-desc topThree">
          <div class="project-card-con-desc-menu">
            是否一件代发工程：{{data.isOneDistribution==1?'是':'否'}}
          </div>
        </div>
        <div class="project-card-con-specification">
          <div class="project-card-con-specification-menu">
            <span>协议数量 <span class="num" >{{ data.totalNum }}</span> 台</span>
            <span class="project-card-con-specification-menu-gary"
              >已下单 <span  class="num" > {{ data.finishOrder }} </span> 台</span
            >
            <span class="num" >剩余 {{ data.totalNum - data.finishOrder }} 台</span>
            <span class="look-detail" @click="goOrderList">查看关联订单</span>
          </div>
          <div class="project-card-con-specification-menu">
            <span class="project-card-con-specification-menu-gary"
              >共 <span style="color: #00AAA6;">{{ data.epInfoDetail.length }}</span>个商品可选</span
            >
            <span
              class="project-card-con-specification-menu-isOpen"
              @click="isCollapseProOpen"
              v-if="!data.isCollapse"
              >
               <span class="icon">
                <img src="@/assets/order/编组 6@2x.png" alt="">
<!--                <img src="@/assets/order/下2.png" alt="">-->
              </span>
            </span
            >
            <span
              class="project-card-con-specification-menu-isOpen project-card-con-specification-menu-isClose"
              @click="data.isCollapse = false"
              v-else
              >
              <span class="icon">
                <img src="@/assets/order/编组 6@2x(1).png" alt="">
<!--                <img src="@/assets/order/上.png" alt="">-->
              </span>
            </span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="project-collapse-box" v-if="data.isCollapse">
      <div class="project-collapse">
        <div class="project-collapse-title">商品选择</div>
        <div
          class="project-collapse-menu"
          v-for="(item, index) in data.epInfoDetail"
          :key="index"
        >
          <h-check
            v-if="data.status == 1"
            :canSelect="item.errorMessage || data.status != 1"
            :isCheck.sync="item.choose"
            @datachange="dataChange(item)"
          />
<!--          <div v-else class="check"></div>-->
         <div class="project-collapse-menu-everyBox"
              :style="{'margin-left':data.status == 1?'16px':'','margin-bottom':data.status != '1'?'24px':'0px','width': data.status != '1'?'1140px':'1108px'}">
           <div class="project-collapse-menu-everyBox-img">
             <span>
               <h-img
                 :imgUrl="item.img"
                 :errorImg="item.defaultImg"
                 :isUseBasePath="true"
               ></h-img>
             </span>
           </div>
           <div class="project-collapse-menu-everyBox-data">
             <div class="project-collapse-menu-everyBox-data-title">
               <span  v-if="item.freeDelivery == '1' " class="freeShipping">免运费</span>
<!--               <span  v-if="item.productLabel && item.productLabel.indexOf('15691143850')!==-1 " class="freeShipping">免运费</span>-->
               <span  v-if="item.productLabel && item.productLabel.indexOf('15691143849')!==-1 " class="bright">璀璨</span>
               {{ item.productName }}
             </div>
             <div class="project-collapse-menu-everyBox-data-desc">
               品牌：{{ item.brandName }} <span class="color"></span>颜色：{{
                 item.color
               }}
             </div>
             <div class="project-collapse-menu-everyBox-data-desc">
               体积：{{ item.loadVolume }}m³
               <span class="color">批次：{{
                 item.batchId_name
               }}
               </span>
             </div>

           </div>
           <div class="project-collapse-menu-everyBox-data project-collapse-menu-everyBox-data">
             <div class="project-collapse-menu-everyBox-data-title">
               ￥{{ new Number(item.singlePrice).toFixed(2) }}
<!--               <span-->
<!--               v-if="item.singlePrice != item.standardPrice"-->
<!--               class="falseAmount"-->
<!--             >￥{{  new Number(item.standardPrice).toFixed(2)}} </span-->
<!--             >-->
             </div>
             <div class="project-collapse-menu-everyBox-data-desc">
               定价组： {{ item.priceGroupId_name || '工程机' }}
<!--               formatPrice(item.buckle)-->
             </div>
             <div class="project-collapse-menu-everyBox-data-desc">
               竣工后补差金额(元/套)：￥{{ item.diffentPrice?Number(item.diffentPrice).toFixed(2):'0.00' }}
             </div>
           </div>
           <div class="project-collapse-menu-everyBox-data project-collapse-menu-everyBox-data">
             <div class="project-collapse-menu-everyBox--data-title">
               协议数量：{{ item.amount }}
             </div>
             <div class="project-collapse-menu-everyBox-data-desc">
               已下单 {{ item.finishOrder }} 台，剩余
               {{ item.amount - item.finishOrder }} 台
             </div>
           </div>
           <div class="project-collapse-menu-everyBox-control">
             <div
               class="project-collapse-menu-everyBox-control-box"
               v-if="data.status == 1"
             >
               <number-box
                 @getNumber="dataChange(item)"
                 :curVal.sync="item.chooseNum"
                 :minVal="1"
                 :maxVal="item.amount - item.finishOrder"
                 :isDisabled="item.errorMessage ? true : false"
               ></number-box>
               <!--              <p class="can-no-buy" v-if="item.errorMessage">部分商品没有提货标准价，无法下单！</p>-->
               <p class="can-no-buy" v-if="item.errorMessage">
                 <img class="warn-img" :src="warningImg">{{ item.errorMessage }}</p>
               <!--              <p class="can-no-buy" v-if="item.errorMessage">{{item.errorMessage}}</p>-->
             </div>
           </div>
         </div>
        </div>
      </div>
      <!-- 底部结算 -->
      <div class="project-box-header" v-if="data.status == 1">
        <div class="project-box-header-data">
          <div class="project-box-header-data-shop">
            <a-checkbox
              v-model="isSelectAll"
              @change="checkAll"
              :disabled="getAllSelectDisabled()"
              >全选
            </a-checkbox>
          </div>
          <div class="project-box-header-data-price">
            <div class="project-box-header-data-price-inset left-pad">
              <span>已选择 {{ chooseProductClass }} 种商品</span>
              <span>共 {{ chooseProductNum }} 件</span>
              <span @click="getInfo">
                体积合计：{{ new Number(chooseProductVolume).toFixed(3) }}m³
                <!-- <i
                  class="iconfont icon-tijiwenti-pc toast"
                  style="color:#00AAA6;margin-left:4px"
                ></i> -->
                <volume-pop></volume-pop>
              </span>
            </div>
<!--            <div class="project-box-header-data-price-inset">-->
<!--              <span-->
<!--                >-->
<!--                <span style="color: #262626">合计：</span>-->
<!--                <h6 style="font-size: 12px;color:#D9161C"">￥</h6>-->
<!--                <h6 style="font-size: 18px;color:#D9161C">-->
<!--                  {{ new Number(chooseProductPrice).toFixed(2) }}-->
<!--                </h6>-->
<!--              </span-->
<!--              >-->
<!--            </div>-->
            <div class="pur-commodity-total-price">
              <p class="hj">合计：</p>
              <p class="priceNum">
                <span class="fh">￥</span>
                <span class="jg before"  style="font-size: 22px;font-weight: 500;color:#D9161C;">{{ chooseProductPrice ? Number(chooseProductPrice).toFixed(2).toString().split('.')[0] : '0' }}</span>
                <span class="jg after" style="font-size:12px;"><i class="dian">.</i>{{ chooseProductPrice ? Number(chooseProductPrice).toFixed(2).toString().split('.')[1] : '00'}}</span>
              </p>
            </div>


          </div>
        </div>
        <div class="project-box-header-submit">
          <h-btn
            class="btn"
            @click="handleSubmit"
            width="188"
            height="54"
            border-radius="0"
            fontSize="16"
            :customType="data.status == 1 ? '' : 3"
            >立即下单
          </h-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HCheck from "./HCheck";
// import { getEngineerOrderDetail } from "@/utils/api";
import afterImg from "@/assets/home/after.png";
import onImg from "@/assets/home/oning.png";
import preImg from "@/assets/home/pre.png";
import warningImg from '@/assets/icon/warning.png'

export default {
  name: "projectCollapse",
  components: {
    HCheck
  },
  data() {
    return {
      warningImg:warningImg,
      image: {
        afterImg: afterImg,
        onImg: onImg,
        preImg: preImg
      },
      isCollapse: false, //是否折叠
      productDetail: [],
      chooseProductNum: 0,
      chooseProductPrice: 0,
      chooseProductVolume: 0,
      chooseProductClass: 0,
      isSelectAll: false
    };
  },
  props: {
    data: {
      type: [Array, Object],
      default: () => []
    },
    dataIndex: {
      type: [Number, String]
    }
  },
  computed: {
    canSelect: () => {
      let num = 0;
      this.data.productItem.forEach(element => {
        if (element.canSelect) {
          num += 1;
        }
      });
      return num;
    }
  },
  watch: {
    data: {
      handler: function(val) {
        if (this.data.status == 0) {
          this.data.image = this.image.preImg;
        } else if (this.data.status == 1) {
          this.data.image = this.image.onImg;
        } else if (this.data.status == 2) {
          this.data.image = this.image.afterImg;
        }
      },
      immediate: true
    }
  },
  
  mounted() {
    this.$nextTick(()=>{
      // 单个商品默认选中
      if(this.data.epInfoDetail && this.data.epInfoDetail.length == 1){
        this.data.epInfoDetail[0].choose = true;
        this.dataChange();
      };
    })
    
  },
  methods: {
    formatPrice(price) {
      // if (Math.floor(price) === price) {
      //   return price
      // } else {
      return (Math.round(new Number(price) * 1000) / 1000).toFixed(2)
      // }
    },
    goOrderList() {
      // 重新打开新页面
      let routeUrl = this.$router.resolve({
           path: "/order/cis",
           query: {
          projectCode: this.data.projectCode
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    getAllSelectDisabled() {
      let flag = true;
      this.data.epInfoDetail.forEach(it => {
        if (!it.errorMessage) {
          flag = false;
        }
      });
      return flag;
    },
    // 展开
    async isCollapseProOpen() {
      this.data.isCollapse = true;
    },

    handleSubmit() {
      if (this.data.status != 1) {
        // this.$message.warning("只能结算活动进行中的商品！");
        const msg =  this.$configTotal({
          route: this.$route.fullPath,
          id:'14180000052'
        })
        this.$message.warning(msg&&msg.msgDesc);
        return;
      }
      const data = this.data;
      let params = {
        flag: 2,
        mainId: data.id
      };
      const ids = [];
      const counts = [];
      let count = 0;
      const arrFilter = data.epInfoDetail.filter(it => it.choose);
      let flag = false;
      arrFilter.forEach(item => {
        ids.push(item.id);
        counts.push(item.chooseNum);
        count += item.chooseNum;
        if (item.chooseNum == 0) {
          flag = true;
        }
      });
      if (flag) {
        // this.$message.warning("商品数量不可为0！");
        const msg =  this.$configTotal({
          route: this.$route.fullPath,
          id:'14180000053'
        })
        this.$message.warning(msg&&msg.msgDesc);
        return false;
      }
      if (arrFilter.length == 0) {
        // this.$message.warning("请选择规格！");
        const msg =  this.$configTotal({
          route: this.$route.fullPath,
          id:'14180000054'
        })
        this.$message.warning(msg&&msg.msgDesc);
        return false;
      }
      if (count == 0) {
        // this.$message.warning("商品数量不可为0！");
        const msg =  this.$configTotal({
          route: this.$route.fullPath,
          id:'14180000053'
        })
        this.$message.warning(msg&&msg.msgDesc);
        return false;
      }
      params.ids = ids.join(",");
      params.counts = counts.join(",");
      this.$router.push({
        path: "/confirm/specialZonesGC",
        query: params
      });
    },

    //获取物流提示
    getInfo() {
      // this.$info({
      //   title: "体积提示",
      //   content: this.$store.state.user.getVolumnText
      // });
    },

    //当参数发生变化
    dataChange(cherkItem) {
      this.initProduct();
      let chooseProductNum = 0,
        chooseProductPrice = 0,
        chooseProductClass = 0,
        chooseProductVolume = 0;

      this.data.epInfoDetail.forEach(item => {
        if (item.choose) {
          chooseProductNum += parseInt(item.chooseNum);
          chooseProductPrice += item.chooseNum * item.singlePrice;
          chooseProductClass += item.chooseNum > 0 ? 1 : 0;
          chooseProductVolume += item.chooseNum * item.loadVolume;
        }
      });

      this.chooseProductNum = chooseProductNum;
      this.chooseProductPrice = chooseProductPrice.toFixed(2) || "0.00";
      this.chooseProductClass = chooseProductClass;
      this.chooseProductVolume = chooseProductVolume.toFixed(3) || "0.00";
    },

    //初始化产品信息
    initProduct() {
      this.chooseProductNum = 0;
      this.chooseProductPrice = 0;
      this.chooseProductClass = 0;
      this.chooseProductVolume = 0;
    },

    //全选
    checkAll() {
      this.data.epInfoDetail.forEach(items => {
        items.choose = this.isSelectAll && !items.errorMessage ? true : false;
      });
      this.dataChange();
    }
  }
};
</script>
<style lang="less" scoped>
@cardImgW: 198px;
@col26: #262626;
@col77: #777777;
@accountBtnW: 200px;
@f12: 12px;
@f14: 14px;
@f16: 16px;
@f18: 18px;
.project-box {
  width: 1188px;
  height: auto;
  margin: 0 auto;
  background: #fff;
  // 工程内容 非折叠区
  .project-card {
    display: flex;
    box-sizing: border-box;
    padding: 16px 0px 16px 16px;

    &-img {
      width: @cardImgW;
      height: 260px;
      position: relative;
      border-radius: 8px;
      overflow: hidden;

      .i-tag {
        position: absolute;
        top: 0;
        left: 0;

        .content {
          // padding: 0 8px;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 10px;
          cursor: pointer;
          color: white;
          width: 50px;
          line-height: 20px;
          height: 20px;
          font-size: 12px;
          text-align: center;
        }

        .bg0 {
          background-color: #FF7474;
        }

        .bg1 {
          background-color: #18D1BC;
        }

        .bg2 {
          background-color: #9BB1D2;
        }
      }

      .i-time {
        position: absolute;
        width: 198px;
        top: 30px;
        left: 0;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #262626;
        line-height: 12px;

        .spec {
          margin: 0 4px;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    &-con {
      height: 260px;
      width: calc(100% - @cardImgW);
      box-sizing: border-box;
      padding: 30px 24px 0 20px;

      &-title {
        color: @col26;
        font-size: @f14;
        line-height: 14px;
        font-weight: 500;
        text-align: left;

        .lab {
          margin-right: 16px;
          //min-width: 150px;
        }

        .orgname-wrap {
          float: right;
          color: #00aaa6;
        }
      }

      &-desc {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 24px;
        line-height: 14px;

        &-menu {
          font-size: @f14;
          color: #777;
          font-weight: 400;
          width: 50%;
          text-align: left;

          &:first-child {
            width: 394px;
          }
        }
      }
      .topOne{
        margin-top: 21px;
      }
      .topTwo{
        margin-top: 18px;
      }
      .topThree{
        margin-top: 21px;
      }

      &-specification {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 44px;
        width: 930px;
        height: 44px;
        background: linear-gradient(90deg, #EEF9F9 0%, #FBFBFB 100%);
        border-radius: 6px;
        padding-left:16px;
        padding-right:18px;
        &-menu {
          font-size: @f14;
          line-height: @f14;
          color: @col26;
          .num {
            color: #00AAA6
          }

          &-gary {
            color: @col26;
            padding: 0 24px;
          }

          &-isOpen {
            cursor: pointer;
            //color: #1890ff;
            color: #777;
            &:hover {
              color: #00aaa6;
            }
            // .iconfont {
            //   font-size: 8px;
            //   margin-left: 4px;
            //   line-height: 22px;
            // }
            .icon {
              width: 16px;
              height: 16px;
              display: flex;
              flex-direction: column;
              position: relative;
              top:4px;
              //img:first-child{
              //  z-index:1000;
              //}
              //img:last-child{
              //  z-index:1200;
              //  margin-top: -4px;
              //}
            }
          }

          .look-detail {
            color: #1890ff;
            margin-left: 24px;
            cursor: pointer;
          }

          &-isClose {
            .iconfont {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  .project-collapse {
    text-align: left;

    &-title {
      padding:8px 24px 0px 24px;
      line-height: 22px;
      font-size: @f16;
      color: @col26;
      font-weight: 500;
    }

    &-menu {
      padding: 16px 24px 0px 24px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      margin: 0 auto;

      .check {
        width: 16px;
        height: 20px;
      }


      &-everyBox {
        position: relative;
        //width: 1108px;
        height: 144px;
        background: #F7F7F7;
        display: flex;
        align-items: center;
        border-radius:8px;
        //margin-left:16px;

        &-img {
          //width: 100px;
          //height: 100px;
          //margin-left: 30px;
          //span{
          //  width: 100px;
          //  height: 100px;
          //  img {
          //    width: 80px;
          //    height: 80px;
          //  }
          //}
          width: 100px;
          height: 100px;
          background: #FFFFFF;
          border-radius: 5px;
          margin-left: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            width: 80px;
            height: 80px;
            img {
              width: 100%;
              height: 100%;
            }
          }

        }

        &-data {
          width: 248px;
          height: 98px;
          margin-left: 24px;
          text-align: left;
          padding-top: 2px;

          &-title {
            font-size: @f14;
            color: @col26;
            font-weight: 500;
            .freeShipping {
              font-size: 12px;
              color: #fff;
              width: 42px;
              height: 16px;
              text-align: center;
              line-height: 16px;
              background: linear-gradient(90deg, #FF7C45 0%, #F63737 100%);
              border-radius: 2px;
              margin-right: 0px;
              font-weight: 400;
            }
            .bright {
              font-size: 12px;
              color: #433538;
              width: 30px;
              height: 16px;
              text-align: center;
              line-height: 16px;
              background: linear-gradient(90deg, #D49879 0%, #FEF2DC 50%, #D49879 100%);
              border-radius: 2px;
              margin-left: 4px;
              font-weight: 400;
            }
          }

          &-desc {
            font-size: @f12;
            color: #777;
            margin-top: 8px;

            .color {
              margin-left: 12px;
            }
          }
        }

        &-data2 {
          width: 200px;
        }

        &-control {
          padding-top: 2px;

          &-box {
            max-width: 160px;
            height: 98px;
            text-align: left;
            position:absolute;
            top: 28px;
            right: 50px;
            .number-box {
              /deep/ .btn-number {
                background: #E4F8F7;
                height: 36px;
              }
              /deep/ .disbled {
                background: #eee;
              }

              /deep/ .number-container {
                width: 70px !important;
                height: 38px;
                border-top:1px solid #E4F8F7 ;
                border-bottom:1px solid #E4F8F7 ;
              }

              //margin-top: 22px;
            }
            input {
              text-align: center;
            }
          }
        }
      }

    }
  }

  .project-account {
    width: 1188px;
    background: #fff;
    margin: 0 auto;
    border-top: 16px solid #f7f7f7;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 18px 54px 18px 16px;
      width: calc(100% - @accountBtnW);

      &-right {
        &-price {
          font-size: 24px;
          font-weight: 600;
        }

        .color-red {
          color: #d9161c;
        }
      }
    }

    &-btn {
      width: @accountBtnW;
      height: 100%;

      &-a {
        width: 100%;
        height: 100%;
        border-radius: 0;
        padding: 18px 0;
        box-sizing: border-box;
        font-size: 16px;
      }
    }
  }

  .project-box {
    width: 1188px;
    margin: 16px auto 0;
    height: 54px;
    display: flex;
    align-items: center;
    &-header {
      box-sizing: border-box;
      padding: 24px 27px 22px 24px;
      background: #fff;
      display: flex;

      &-data {
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        height: 54px;
        align-items: center;
        width: 100%;

        &-price {
          height: 100%;
          color: #777777;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-end;
          width: 450px;
          margin-right: 16px;
          padding: 0px 0;

          .left-pad {
            position: relative;
            left: 4px;
          }

          &-inset {
            display: flex;
            flex-wrap: nowrap;
            //line-height: 16px;

            span {
              display: flex;
              align-items: center;
              margin-left: 18px;

              .toast {
                width: 20px;
              }
            }

          }
          .pur-commodity-total-price{
            margin: -8px 0px 0px 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            .hj{
              height: 16px;
              font-size: 14px;
              font-weight: 500;
              color: #262626;
              line-height: 16px;
            }
            .priceNum{
              .fh {
                color: #D9161C;
                font-size: 14px;
              }
              .jg {
                color: #D9161C;
                font-size: 22px;
              }
            }

          }
        }
      }

      &-submit {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .btn {
          border:none;
          margin-left: 0px;
          width: 188px;
          height: 54px;
          background: linear-gradient(90deg, #FC3F85 0%, #FD3D23 100%)!important;
          color: #fff;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
          font-size: 16px;
        }
      }
    }

    &-content {
      margin-top: 12px;
    }
  }
}

.falseAmount {
  margin-left: 8px;
  color: #aaa;
  text-decoration: line-through;
}
.can-no-buy {
  padding-top: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #FF8F3C;
  line-height: 16px;
  .warn-img{
    width: 14px;
    margin-right: 6px;
    vertical-align: text-bottom;
  }
}
</style>
