/*
 * @Author: HIDESK\yanbenrong.ex ybr15650585591@163.com
 * @Date: 2023-12-13 10:30:12
 * @LastEditors: HIDESK\yanbenrong.ex ybr15650585591@163.com
 * @LastEditTime: 2024-02-27 08:41:29
 * @FilePath: \B2B\src\views\engineering\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { mapState } from "vuex";
import { getEngineerOrder } from "@/utils/api";
import ProjectCollapse  from "./components/ProjectCollapse";
export default {
  components:{
    ProjectCollapse
  },
  data() {
    return {
      nolistImg:require('@/assets/order/noList.png'),
      showList:false,
      windowList: [],
      sortList: [
        {
          name: "开始时间",
          sortStatus: 1 //0默认 1降 2升序
        }
      ],
      dataList: [],
      pageLoadFlag: false,
      pageSize: 20,
      total: 0,
      totalPages: 0,
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/engineering",
          name: "engineering",
          title: "工程专区"
        }
      ],
      form: this.$form.createForm(this, { name: "horizontal_login" }),
      produceList: [],
      collapse: false,
      filterFrom: {
        searchStr: "", //名称
        status: "1",
        sortType: "desc",
        orgId: "",
        pageSize: 20,
        pageNo: 1 //当前页
      }
    };
  },
  computed: {
    ...mapState({
      //处理后的筛选数据list
      filList: (state) => state.filtrate.filList,
      //筛选组件加载loadding
      filterLoadFlag: (state) => state.filtrate.isLoading,
      filterConfigStr: (state) => state.filtrate.filterConfigStr,
    }),
  },
  mounted() {
    // //筛选后续操作的入参 加后台已定义的 formCode 便于筛选后查询
    this.filterFrom = { ...this.filterFrom, formCode:this.filterConfigStr ? this.filterConfigStr: '' };
    
    //默认查询入参
    if (this.$route.query.condition) {
      this.filterFrom.condition = this.$route.query.condition;
    }
    //最后一项面包屑显示
    let breadcrumbLast = {
      title: this.$route.query.customName
        ?  this.$route.query.customName
        : "",
    };
    //最后一项面包屑
    if (breadcrumbLast.title) {
      this.$set(this.breadcrumbData, this.breadcrumbData.length, breadcrumbLast);
    }
    // //请求筛选公共接口 获取筛选数据
    // this.$store.dispatch("filtrate/systemConfigFormItem");
    // this.getEngineerOrderList();
    // this.queryOrgAndMatkl();
  },
  methods: {
    async getEngineerOrderList(type) {
      this.pageLoadFlag = true;
      this.showList = false;
      try {
        const res = await getEngineerOrder(this.filterFrom);
        let { engineeringOrders, totalRows } = res.data;
        engineeringOrders.forEach(item => {
          item["isCollapse"] = this.collapse;
          item.epInfoDetail.forEach(detail => {
            detail.choose = false;
            detail.chooseNum = 1;
            detail.chooseProductClass = 0;
            detail.chooseProductVolume = 0;
          });
        });
        if(type=='contract'){
          this.produceList = this.produceList.concat(engineeringOrders)
        }else{
          this.produceList = engineeringOrders;
        }
        this.total = totalRows;
        this.totalPages = res.data.totalPages
        this.showList = true;
        this.pageLoadFlag = false;
        this.filterFrom.pageNo += 1
      } catch (error) {
        this.pageLoadFlag = false;
        this.showList = true;
        this.produceList = []
        this.total = 0;
        this.totalPages = 0
        console.error(error);
      }
    },
    // 跳转页面
    jumpImport(){
      this.$router.push({
        path:'/batchImportPage',
        query:{
          // 传入导入订单类型
          type:'xtwSalesImportEpOrder',
          excelName:'工程订单导入模板'
        }
      })

    },
    handlePageChange() {
      this.getEngineerOrderList();
    },

    onSearch(searchStr) {
      this.preperList = [];
      this.collapse = false;
      this.filterFrom = { ...this.filterFrom, searchStr, pageNo: 1 };
      this.getEngineerOrderList();
    },
    onChange(searchStr) {
      this.filterFrom.searchStr = searchStr
    },

    //筛选排序
    sortClick(data) {
      const { sortStatus } = data;
      this.collapse = false;
      this.filterFrom = {
        ...this.filterFrom,
        sortType: sortStatus == 0 ? "" : sortStatus == 1 ? "desc" : "asc",
        pageNo: 1
      };
      this.getEngineerOrderList();
    },


    onFilterChange(checkedObj) {
      //关闭展开
      this.collapse = false;
      //重置筛选条件 将新条件融合进去
      // this.setNullParams();

      // Object.assign(this.param, checkedObj);
      //合并入参请求对象
      this.filterFrom = { ...this.filterFrom, ...checkedObj[0], pageNo: 1};
      //查询列表
      this.getEngineerOrderList();
    },

    //全部展开收起
    collapseChange() {
      this.produceList.forEach(item => {
        item.isCollapse = this.collapse;
      })
      this.$forceUpdate();
    },

    // 筛选数据重构
    getNewObj(obj) {
      let newArr = [],
        data = {
          id: "",
          name: "全部",
          isActive: true
        };
      for (let key in obj) {
        newArr.push({
          id: key,
          name: obj[key],
          isActive: false
        });
      }
      newArr.unshift(data);
      return newArr;
    }
  }
};
